@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    /*button {*/
    /*  .loading {*/
    /*    @apply h-[24px] w-[24px]*/
    /*  }*/
    /*}*/
    .selection {
        @apply text dark:text-dark hover:bg-selection dark:hover:bg-selection-dark hover:text dark:hover:text
    }

}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#demovideos #deletebutton {
    visibility: hidden;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
    background-color: #7066e0 !important
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-cancel) {
    background-color: #6e7881 !important;
}

.video-js {
    height: 400px !important;
}